<template>
  <div class="space-y-4">
    <asom-card>
      <div class="py-5">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          First Aid Log Information
        </h3>
      </div>
      <asom-alert v-if="error" variant="error" :error-message="error" />
      <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
        <div class="grid sm:grid-cols-1 md:grid-cols-2">
          <asom-form-field label="Body Parts">
            <p class="font-semibold">
              {{ get(details, "bodyParts", "-") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Rendered Treatment">
            <p class="font-semibold">
              {{ get(details, "renderedTreatment", "-") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Injury Diagram">
            <asom-human-diagram-view
              v-if="details.imagefileId"
              :fileId="details.imagefileId"
            />
          </asom-form-field>
          <asom-form-field label="Remarks">
            <p class="font-semibold">
              {{ get(details, "remarks", "-") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Items" class="col-span-2">
            <asom-client-table
              v-if="!isLoading"
              :columns="['inventoryTypeName', 'firstAidBoxName', 'quantity']"
              :data="details.items"
              :filterable="false"
            >
              <template v-slot:header_inventoryTypeName>Item Type</template>
              <template v-slot:header_firstAidBoxName>Box</template>
            </asom-client-table>
            <div v-else class="text-center">
              <asom-icon icon="spinner" class="animate-spin" />
            </div>
          </asom-form-field>
        </div>
      </div>
      <div class="col-span-2 flex justify-end space-x-4 pt-8">
        <asom-button text="Back" variant="secondary" @click="$router.go(-1)" />
      </div>
    </asom-card>
  </div>
</template>

<script>
import get from "lodash.get";
import { getFirstAidLogDetails } from "../../../../services/inventory.service";

export default {
  name: "ViewFirstAidBox",
  data() {
    return {
      details: {
        bodyParts: get(this.$route.params, "bodyParts", "-"),
        renderedTreatment: get(this.$route.params, "renderedTreatment", "-"),
        remarks: get(this.$route.params, "remarks", "-"),
        items: [],
        imagefileId: null,
      },
      error: null,
      isLoading: false,
    };
  },
  mounted() {
    this.getBoxDetails();
  },
  methods: {
    get,
    async getBoxDetails() {
      this.isLoading = true;
      const result = await getFirstAidLogDetails({
        inventoryTransactionId: get(
          this.$route.params,
          "inventoryTransactionId"
        ),
      });
      if (result.success) {
        this.setFormData(get(result.payload, "model", {}));
        this.isLoading = false;
      } else {
        this.isLoading = false;
        this.error = result.payload;
        this.$scrollTop();
      }
    },
    setFormData(data) {
      this.details.bodyParts = get(data, "bodyParts", "-");
      this.details.renderedTreatment = get(data, "renderedTreatment", "-");
      this.details.remarks = get(data, "remarks", "-");
      this.details.items = get(data, "firstAidItems", []);
      this.details.imagefileId = get(data, "attachmentId", null);
    },
  },
};
</script>
